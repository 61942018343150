<template>
    <div v-if="authed">
        <v-data-table :headers="headers" :items="items" :loading="loading" loading-text="加载中...">
            <template v-slot:item.avatarURL="{ item }">
                <v-avatar size="40" style="border:1px solid #ddd;">
                    <v-img :src="item.avatarURL"/>
                </v-avatar>
            </template>
            <template v-slot:item.fee="{ item }">
                {{formatFee(item)}}
            </template>
            <template v-slot:item.duty="{ item }">
                {{formatDuty(item)}}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:footer>
                <v-icon large class="ml-4 mt-3" style="position:absolute" @click="addItem()">mdi-account-plus</v-icon>
            </template>
            <template v-slot:item.signature="props">
				<v-edit-dialog :return-value.sync="props.item.signature" large save-text="保存" cancel-text="取消"
					@save="saveSignature(props.item)" @open="onOpenSignature(props.item)">
					{{props.item.signature ? '有' : '无'}}
					<template v-slot:input>
						<v-avatar center tile size="200">
							<v-img v-if="signature" :src="signature" contain/>
							<v-icon v-else x-large class="material-icons-outlined">note_alt</v-icon>
						</v-avatar>
						<v-file-input placeholder="选取签名图片文件" label="签名图片" accept="image/png"
							style="width:200px" v-model="signatureFile" @change="onFileChange"/>
					</template>
				</v-edit-dialog>
			</template>
        </v-data-table>
        <doctor2 ref="dialog" @change="fetchData"></doctor2>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {duties} from '../workset'
    import doctor2 from "../components/Doctor2.vue"

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'', value:'avatarURL', width:100, sortable: false},
                    {text:'姓名', value:'name', width:100},
                    {text:'诊所', value:'region', width:150},
                    {text:'职级', value:'title', width:150},
                    {text:'排序', value:'rank', width:80},
                    {text:'业务', value:'duty', width:200, sortable: false},
//                    {text:'服务费', value:'fee', width:200, sortable: false},
                    {text:'修改', value:'actions', width:80, sortable: false},
                    {text:'签名', value:'signature', width:100},
                ],
                items: [],
                loading: false,
				signature: null,
				signatureFile: null,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('医生管理');
			if (!this.authed) return;
            this.fetchData();
        },
        methods: {
            formatFee(item) {
                return JSON.stringify(item.fee);
            },
			formatDuty(item) {
				return item.duty.map(x => {
					const r = duties.find(y => y.value === x);
					return r ? r.text : '';
				}).join(', ');
			},
			async fetchData() {
				this.loading = true;
				const db = this.$tcbapp.database();
				try {
					const res = await db.collection('wp2doctor').limit(200).orderBy('rank','desc').get();
					const s3 = 'cloud://huasan-6gaadaha7ceb62a9.6875-huasan-6gaadaha7ceb62a9-1300427821';
					const s4 = 'https://6875-huasan-6gaadaha7ceb62a9-1300427821.tcb.qcloud.la';
					const defaultAvatar = s4 + '/avatar/doctor_default.jpg';
					res.data.forEach(x => {
						x.avatarURL = x.avatar ? x.avatar.replace(s3, s4) : defaultAvatar;
					})
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            editItem(item) {
                this.$refs.dialog.show(item);
            },
            addItem() {
                this.$refs.dialog.show();
            },
			async saveSignature(item) {
                if (this.signatureFile) {
                    const filename = 'signature/doctor/' + item.username + '.png';
                    const rf = await this.$tcbapp.uploadFile({
                        config: {env: 'huasan-6gaadaha7ceb62a9'},
                        cloudPath: filename,
                        filePath: this.signatureFile
                    });
                    await this.$tcbapp.callFunction({name:"wp2mp",
                        data:{
                            funcname:'saveDoctorSignature',
                            data: {
                                id: item._id,
                                cloudfile: rf,
                            }
                        }
                    });
                    item.signature = rf.download_url;
                } else {
                    await this.$tcbapp.callFunction({name:"wp2mp",
                        data:{
                            funcname:'saveDoctorSignature',
                            data: {
                                id: item._id,
                                cloudfile: null,
                            }
                        }
                    });
                    item.signature = null;
                }
			},
			onOpenSignature(item) {
                this.signature = item.signature;
				this.signatureFile = null;
			},
			onFileChange(e) {
				if (e) {
					this.signatureFile = e;
					this.signature = URL.createObjectURL(e);
				} else {
					this.signatureFile = null;
					this.signature = null;
				}
			},
        },
        components: {
            doctor2
        }
    }
</script>