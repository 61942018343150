var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"加载中..."},scopedSlots:_vm._u([{key:"item.avatarURL",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"border":"1px solid #ddd"},attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":item.avatarURL}})],1)]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFee(item))+" ")]}},{key:"item.duty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuty(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}},{key:"footer",fn:function(){return [_c('v-icon',{staticClass:"ml-4 mt-3",staticStyle:{"position":"absolute"},attrs:{"large":""},on:{"click":function($event){return _vm.addItem()}}},[_vm._v("mdi-account-plus")])]},proxy:true},{key:"item.signature",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.signature,"large":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "signature", $event)},"update:return-value":function($event){return _vm.$set(props.item, "signature", $event)},"save":function($event){return _vm.saveSignature(props.item)},"open":function($event){return _vm.onOpenSignature(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-avatar',{attrs:{"center":"","tile":"","size":"200"}},[(_vm.signature)?_c('v-img',{attrs:{"src":_vm.signature,"contain":""}}):_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"x-large":""}},[_vm._v("note_alt")])],1),_c('v-file-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"选取签名图片文件","label":"签名图片","accept":"image/png"},on:{"change":_vm.onFileChange},model:{value:(_vm.signatureFile),callback:function ($$v) {_vm.signatureFile=$$v},expression:"signatureFile"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.signature ? '有' : '无')+" ")])]}}],null,false,2814808677)}),_c('doctor2',{ref:"dialog",on:{"change":_vm.fetchData}})],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }